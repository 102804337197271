<template>
  <div class="companyinfo-wrap" :class="{ 'companyinfo-pc-wrap': isPc }">
    <div class="banner-common" :class="{ 'banner-pc-common': isPc }">
      <img src="../../assets/img/about.png" v-if="!isPc" alt="" />
      <img src="../../assets/img-pc/about-pc.png" v-else alt="" />
      <div class="text">
        <span class="chanese">关于儒彧</span>
        <span class="english">About xiermei</span>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="company-wrap">
      <title-module :titleInfo="companyInfo" :isPc="isPc" />
      <div class="content">
        <div class="img">
          <img src="../../assets/img/company.png" alt="" />
        </div>
        <div class="text">
          <p>
            安徽儒彧电子信息科技有限公司（简称“儒彧”）成立于2018年1月，是喜尔美集团旗下科技型公司，立志于为城市提供全品类垃圾分类一站式解决方案。产品喜尔美云柜以前端智能化硬件产品、喜尔美云柜APP为服务端口，依托后端物联网、云计算、大数据、环保信用体系、智能运营系统，构建城市场景的分类运营模型，为政府、企业、再生资源公司、垃圾处理企业打造产业生态，推动垃圾分类“两网融合”模式创新，最终实现生活垃圾分类收集、分类清运、分类处理的一站式服务体系，提升城市再生资源回收率。助力智慧社区、智慧城市建设，有效推进绿色可持续发展。
          </p>
         
        </div>
      </div>
    </div>

    <!-- 公司基地 -->
    <div class="com-base">
      <titleModule :titleInfo="comBase" :isPc="isPc" />
      <div class="content-wrap">
        <div class="content-item">
          <img src="../../assets/img/jidi-1.png" alt="" />
          <span>公司宿舍楼</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-2.png" alt="" />
          <span>公司鸟瞰图</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-3.png" alt="" />
          <span>仓储物流基地</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-4.png" alt="" />
          <span>喜尔美大厦景观图</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-5.png" alt="" />
          <span>喜尔美大厦景观图</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-6.png" alt="" />
          <span>喜尔美大厦景观图</span>
        </div>
      </div>
    </div>
    <!-- 公司荣誉  -->
    <div class="com-certificate">
      <titleModule :titleInfo="comCertificate" :isPc="isPc" />
      <div class="content-wrap">
        <img src="../../assets/img/certificate-1.png" alt="" />
        <img src="../../assets/img/certificate-2.png" alt="" />
        <img src="../../assets/img/certificate-3.png" alt="" />
        <img src="../../assets/img/certificate-4.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import titleModule from "../../components/title";
export default {
  name: "company-info",
  props: ["isPc"],
  components: {
    titleModule
  },

  data() {
    return {
      companyInfo: {
        titleRed: "公司",
        titleWhite: "简介",
        remark: "COMPANY PROFILE"
      },
      comBase: {
        titleRed: "公司",
        titleWhite: "基地",
        remark: "COMPANY BASE"
      },
      comCertificate: {
        titleRed: "公司",
        titleWhite: "荣誉",
        remark: "COMPANY Certificate"
      }
    };
  },

  methods: {},

  created() {}
};
</script>

<style lang="less" scoped>
@import "./companyInfo.less";
companyinfo-wrap {
  background-color: #f7f7f7;
}
.company-wrap {
  padding: 48px;
  background-color: #fff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    .img {
      margin: 40px 0 20px;
      img {
        width: 1030px;
        height: 639px;
      }
    }
    .text {
      text-align: left;
      p {
        font-size: 30px;
        line-height: 60px;
        letter-spacing: 0px;
        color: #1d1b1b;
        text-indent: 2em;
      }
    }
  }
}
.com-base,
.com-certificate {
  background-color: #fff;
  padding: 48px 47px 48px 48px;
  margin-top: 20px;
  .content-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .content-item {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
    }
  }
}
.com-base {
  .content-wrap {
    img {
      width: 332px;
      margin: 40px 0 25px;
    }
    span {
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }
  }
}
.com-certificate {
  .content-wrap {
    margin-top: 40px;
    img {
      width: 506px;
      height: 384px;
      margin-bottom: 20px;
    }
  }
}
</style>
